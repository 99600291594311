.date-filter--toggle {
  background-color: transparent;
  color: var(--text-gray-muted);
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  border: none;
  outline: none;
}

.performance--filter-div {
  background-color: #f8f8ff;
  padding: 8px;
  border-radius: 10px;
}
.performance--filter-div .btn-check + .btn {
  border: none;
  border-radius: 12px !important;
}
.performance--filter-div .btn-check:checked + .btn.active,
.performance--filter-div :not(.btn-check) + .btn:active,
.performance--filter-div .btn:first-child:active,
.performance--filter-div .btn.active,
.performance--filter-div .btn.show,
.performance--filter-div .btn-check:hover + .btn {
  color: white;
  background-color: #000000;
  border: none;
  transition: none;
}
