.lead-details-analytic {
  align-self: center;
  width: 100%;
}

.lead-details-analytic .data {
  position: absolute;
  left: 50px;
  top: 80px;
  max-width: 40%;
}

.lead-details-analytic .data .lead-text-data .source,
.lead-details-analytic .data .lead-text-data .category,
.lead-details-analytic .data .lead-text-data .adset1,
.lead-details-analytic .data .lead-text-data .ad {
  transition: opacity cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  opacity: 0;
  padding: 5px 20px;
  border: 5px solid black;
  border-radius: 10px;
  margin-bottom: 20px;
}

.lead-details-analytic .data .lead-text-data .count {
  font-size: 20px;
  font-weight: 500;
}

.lead-details-analytic .sunburst .selected-title {
  font-weight: 100;
  font-size: large;
  text-anchor: middle;
}
