@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@100;200;300;400;500;600;700;800&display=swap");
body {
  margin: 0;
  font-family: "Sarabun", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.apexcharts-canvas .apexcharts-toolbar {
  display: none !important;
}
.range_select {
  min-width: 170px !important;
}
