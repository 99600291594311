.date-btn-container {
  display: flex;
  /* gap: 8px; */
}

.date-btn-container > .btn-arrow {
  width: 25px;
  height: 25px;
  border: 1px solid #dbdada;
  /* border-radius: 4px; */
}

.date-btn-container > .date-btn p {
  border: 1px solid #dbdada;
  padding: 1px 8px;
  margin-bottom: 0px;
  /* border-radius: 5px; */
}

.date-btn-container > .date-btn,
.btn-arrow {
  cursor: pointer;
}

.has-week .react-datepicker__week:hover {
  background-color: #f0f0f0;
}

.range-picker {
  display: flex;
  align-self: center;
  text-align: center;
}

.range-picker input {
  width: 100% !important;
}
