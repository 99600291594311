.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #212529;
  font-weight: bold;
  border-color: none;
  background-color: #fff;
}

.nav-tabs .nav-link:hover {
  color: gray;
}
