// Override default variables before the import
// $body-bg: #f9fbfd;
$body-bg: #f5f8f9;

$card-border-color: #edf2f9;

$input-bg: #fff;

// TODO: Set box shadow of cards
// 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07)

$font-family-base: "Cerebri Sans", sans-serif;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
