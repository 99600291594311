.funnel-analytic {
  position: relative;
}

.funnel-analytic > div.chart-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid darkcyan;
  border-radius: 5px;
  background-color: white;
  visibility: hidden;
  color: darkcyan;
  padding: 4px 8px;
  max-width: 400px;
  margin-top: -11px;
  margin-left: 20px;
}
.funnel-analytic > div.question-heading {
  position: absolute;
  top: 0;
  left: 0;
  color: #4d4d4d;
  font-size: x-large;
  font-weight: bold;
  opacity: 0;
  transition: opacity cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.funnel-analytic > svg {
  width: 100%;
}
.funnel-analytic > svg .top-scale {
  color: dimgray;
}
.funnel-analytic > svg .top-scale domain {
  opacity: 0.3;
}
.funnel-analytic > svg .top-scale text {
  font-size: medium;
  font-weight: bold;
  text-anchor: middle;
}
.funnel-analytic > svg .left-scale .tick line {
  stroke: #808080;
}
.funnel-analytic > svg .left-scale text {
  font-size: small;
  font-weight: bold;
  fill: #808080;
}

.funnel-analytic > svg .fill-color.analytic-landingPage,
.funnel-analytic > svg .fill-color.analytic-question,
.funnel-analytic > svg .fill-color.analytic-zip,
.funnel-analytic > svg .fill-color.analytic-leadStepOne,
.funnel-analytic > svg .fill-color.analytic-lead,
.funnel-analytic > svg .fill-color.analytic-leadCreated,
.funnel-analytic > svg .fill-color.analytic-fbAdLeads,
.funnel-analytic > svg .fill-color.analytic-end,
.funnel-analytic > svg .fill-color.analytic-directly,
.funnel-analytic > svg .fill-color.analytic-restart {
  fill: var(--lead-color);
}
.funnel-analytic > svg .stroke-color.analytic-landingPage,
.funnel-analytic > svg .stroke-color.analytic-question,
.funnel-analytic > svg .stroke-color.analytic-zip,
.funnel-analytic > svg .stroke-color.analytic-leadStepOne,
.funnel-analytic > svg .stroke-color.analytic-lead,
.funnel-analytic > svg .stroke-color.analytic-leadCreated,
.funnel-analytic > svg .stroke-color.analytic-fbAdLeads,
.funnel-analytic > svg .stroke-color.analytic-end,
.funnel-analytic > svg .stroke-color.analytic-directly,
.funnel-analytic > svg .stroke-color.analytic-restart {
  stroke: var(--lead-color);
}

.funnel-analytic > svg .data-element.selected {
  fill: darkcyan;
}
.funnel-analytic > svg .data-element > .clickable-node > * {
  cursor: pointer;
}
.funnel-analytic > svg .data-element > .clickable-node > .bar-circle {
  stroke: white;
}
.funnel-analytic > svg .data-element > .clickable-node > .count-text {
  fill: black;
  text-anchor: end;
  alignment-baseline: central;
  font-size: medium;
  font-weight: bold;
}
.funnel-analytic > svg .data-element > .clickable-node > .loss-count-text {
  fill: var(--fail-status-color);
  text-anchor: start;
  alignment-baseline: central;
  font-size: medium;
  /* font-weight: bold; */
}
.funnel-analytic > svg .data-element > .clickable-node > .percent-text {
  fill: black;
  font-size: smaller;
  text-anchor: middle;
  alignment-baseline: central;
}

.funnel-analytic > svg .data-element > .clickable-node > .bar-percent {
  fill: white;
  stroke: var(--lead-color);
  stroke-width: 1px;
}

.funnel-analytic > svg > .answers g .question-point {
  fill: darkcyan;
}

.funnel-analytic > svg > .answers g .individual-answer {
  cursor: pointer;
}

.funnel-analytic > svg > .answers g > .answer-bar-shadow {
  stroke: white;
  fill: #b4b4b4;
}

/* .funnel-analytic > svg > .answers .question-text {
  fill: #4d4d4d;
  font-size: x-large;
  font-weight: bold;
} */

.funnel-analytic > svg > .answers .question-desc {
  fill: #4d4d4d;
  font-size: small;
}

.funnel-analytic > svg > .answers g > .answer-bar {
  fill: darkcyan;
}
.funnel-analytic > svg > .answers g > .answer-bar-text {
  fill: white;
  font-size: smaller;
  alignment-baseline: central;
}
.funnel-analytic > svg > .answers g > .answer-percent-text {
  text-anchor: end;
  fill: black;
}
.funnel-analytic > svg > .answers g > .answer-count-text {
  font-size: small;
  fill: black;
}
.funnel-analytic > svg > .answers g > .answer-img {
  stroke: #cacaca;
  fill: none;
}

/* .funnel-analytic > svg .analytic-network {
  opacity: 0;
  fill: none;
  transition: opacity cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}
.funnel-analytic > svg .analytic-network.shown {
  opacity: 1;
} */

/* .funnel-analytic > svg .network-lines{
  transition: opacity cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
} */

.funnel-analytic > svg > .network-lines g > .network-line {
  fill: none;
}
.funnel-analytic > svg .line-point {
  stroke: white;
  stroke-width: 1.5;
  fill: black;
}
.funnel-analytic > svg .count-circle {
  stroke: white;
}
.funnel-analytic > svg .count-text {
  stroke: none;
  fill: white;
  text-anchor: middle;
  alignment-baseline: central;
  font-weight: bold;
  font-size: x-small;
}

.kpi-tiles-header {
  display: flex;
  align-items: baseline;
  gap: 6px;
}

.kpi-tiles-header .kpi-date {
  color: grey;
  font-size: 11px;
  flex: 1;
}

.kpi-tiles-header .kpi-version {
  flex: 1;
  display: flex;
  align-self: center;
  align-items: baseline;
  padding-left: 12px;
  border-left: 1px solid #dbdada;
}

.kpi-tiles-header .kpi-version .kpi-version-label {
  color: grey;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  white-space: nowrap;
}

.kpi-tiles-header .kpi-toggle-button {
  height: 25px;
  background-color: transparent;
  color: grey;
  border-color: var(--kpi-toggle-date);
  border: 0;
  border-radius: 0;
  font-size: 13px;
  white-space: nowrap;
}
.kpi-tiles-header .kpi-toggle-button:hover {
  background-color: transparent;
  color: darkgray;
}
.btn-check:checked + .btn {
  background-color: transparent;
  color: var(--kpi-toggle-date);
  border-color: var(--kpi-toggle-date);
  border-bottom: 1px solid var(--kpi-toggle-date);
}

.kpi-date-range-header {
  top: 63px;
}
