.leads-header {
  line-height: normal;
}

.evaluation-toggle-button {
  background-color: transparent;
  color: var(--primary-color);

  font-size: 13px;
  white-space: nowrap;
}

.evaluation-div .btn-check:checked + .btn {
  color: white;
}
.evaluation-div .btn-check:checked[value="lead"] + .btn {
  border-color: var(--lead);
  background-color: var(--lead);
}
.evaluation-div .btn-check:checked[value="conversion"] + .btn {
  background-color: var(--primary-color);
}

.cursor-pointer {
  cursor: pointer;
}

.optimization-table-row .t-success {
  font-weight: bold;
  color: #1ca031;
}

.optimization-table-row .t-danger {
  font-weight: bold;
  color: var(--lead-leadCost-problem-color);
}

.optimization-table-row .open-lead-div {
  position: relative;
  bottom: 20px;
  font-size: smaller;
}

/* 
@media (min-width: 992px) {
  .container-scroll > .row {
    overflow-x: auto;
    white-space: nowrap;
  }
  .container-scroll > .row > .col-md-2 {
    display: inline-block;
    float: none;
  }
}
.container-scroll > .row {
  margin-top: 24px;
}
.container-scroll > .row > .col-md-2 {
  font-weight: bold;
  text-align: center;
} */
