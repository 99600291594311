:root {
  --lead-color: #007de8;
  --lead-badge-bg: #bedef9;
  --employee-color: #e86b00;
  --employee-badge-bg: #f9dabf;
  --lead-leadCost-problem-color: #fc0307;
  --lead-leadCost-problem-badge-bg: #ffe5e6;
  --lead-leadQualify-problem-color: #7d7d7d;
  --lead-leadQualify-problem-badge-bg: #f0f0f0;
  --lead-leadCount-problem-color: #8d21fc;
  --lead-leadCount-problem-badge-bg: #f2e5ff;

  --lead-danger-color: #fc0307;
  --lead-danger-badge-bg: #ffe5e6;
  --lead-success-color: #1ca031;
  --lead-success-badge-bg: #dbf0df;
  /* --secondary-text: #555555; */
  --secondary-text: #5e6e82;
  --danger-color: #dc3545;
  --success-color: #198754;
  --warning-color: #ffc107;
  --primary-color: #0d6efd;
  --pending-status-color: #ffc107;
  --non-active-status-color: #ffc107;
  --success-status-color: #198754;
  --active-status-color: #198754;
  --fail-status-color: #dc3545;
  --icon-gray: #9d9d9d;
  --fieldset-border-color: #c0c4c5;
  --dark-bg-color: #212529;
  --text-gray-muted: #b5b5c3;
  --light-border: #e9eaed;
  --light-grey: #efefef;
  --th-border-color: #dee2e6;
  --activity-divider-color: #7ca9ff;
  --body-light-bg: #f6f8f9;
  --color-light-gray: #afb4ba;
  --field-border-color-regular: #ced4db;
  --hubspot-btn-bg: #ff7707;

  /* Analytics Swatches */
  --landing-page: #0078ff;
  --question: #0078ff;
  --outcomes: #ff4359;
  --zip: #028090;
  --lead: #00a896;
  --lead-created: rgb(32 201 151);
  --kpi-toggle-date: #0078ff;
}
