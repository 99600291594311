.kpi-tile h1.heading {
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 2px;
  line-height: 30px;
}

.kpi-tile p.sub-heading {
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
}

.kpi-tile span.sub-heading {
  font-size: 16px;
  line-height: 25px;
  color: #37393b;
  font-weight: bold;
}

.kpi-tile .bullet-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.kpi-tile .bullet {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.kpi-tile .tile-header {
  padding: 20px;
  padding-bottom: 0px;
}

.kpi-tile .tile-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.kpi-tile .rect-bullet-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 10px;
}
.kpi-tile .rect-bullet {
  height: 3px;
}
